<template>
    <div>
        <Navbar page="Análise denúncia" link="/denuncias" nameLink="Canal de Denúncias"  />
        <div v-if="form" class="mx-4 my-4 md:mx-8 md:my-8">            
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-6">
                    <p class="text-base font-bold text-justify my-1">PROTOCOLO: <span class="font-bold">{{ form.protocolo ? form.protocolo : '' }}</span></p>
                    <p class="text-base font-bold text-justify my-2">Canal: <span class="font-normal">{{ form.canal ? form.canal : '' }}</span></p>
                    <p class="text-base font-bold text-justify my-2">Tipo da denúncia: <span class="font-normal">{{ form.tipoDenuncia ? form.tipoDenuncia : '' }}</span></p>
                    <p v-if="form.tipoDenuncia === 'Identificada' && form.nome" class="text-base font-bold text-justify my-2">Nome denunciante: <span class="font-normal">{{ form.nome ? form.nome : '' }}</span></p>
                    <p v-if="form.tipoDenuncia === 'Identificada' && form.email" class="text-base font-bold text-justify my-2">Email denunciante: <span class="font-normal">{{ form.email ? form.email : '' }}</span></p>
                    <p class="text-base font-bold text-justify my-2">Data da registro: <span class="font-normal">{{ form.dataRegistro | moment("DD/MM/YYYY HH:mm") }}</span></p>
                </div>
            </div>
            <div class="flex" v-if="inicialmenteDesativada">
                <label for="naoContemAvaliacao" class="block text-sm font-medium my-3">
                    <input type="checkbox" v-model="form.ativo" class="rounded-sm" id="naoContemAvaliacao">
                    <span class="ml-2">
                        Ativada <span class="text-red-500">(marque essa opção e salve para reativar a atividade)</span>
                    </span>
                </label>
                <button
                    @click="excluirDefinitivamente"
                    type="button" 
                    class="text-white hover:bg-red-500 bg-red-700 text-sm rounded py-1 px-2 mx-1 my-1">
                    Excluir definitivamente
                </button>
            </div>
            <div class="grid grid-cols-12 gap-4 mt-3">
                <div class="col-span-12 md:col-span-4">
                    <label for="canal" class="block text-sm font-medium">Canal</label>
                    <select required v-model="form.canal" name="canal" id="canal" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option value="Site">Site</option>
                        <option value="Email">Email</option>
                        <option value="Telefone">Telefone</option>
                        <option value="Pessoalmente">Pessoalmente</option>
                        <option value="Outro">Outro</option>
                    </select>
                </div>
                <div class="col-span-12 md:col-span-4">
                    <label for="gravidade" class="block text-sm font-medium">Gravidade atribuída</label>
                    <select required v-model="form.gravidade" name="gravidade" id="gravidade" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option value="Muito baixa">Muito baixa</option>
                        <option value="Baixa">Baixa</option>
                        <option value="Média">Média</option>
                        <option value="Alto">Alto</option>
                        <option value="Muito Alto">Muito Alto</option>
                    </select>
                </div>
                <div class="col-span-12 md:col-span-4">
                    <label for="status" class="block text-sm font-medium">Status</label>
                    <select required v-model="form.status" name="status" id="status" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option value="Pendente">Pendente</option>
                        <option value="Em análise">Em análise</option>
                        <option value="Concluído">Concluído</option>
                    </select>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mt-3">
                <div class="col-span-12 md:col-span-6">
                    <label for="conteudo" class="block text-sm font-medium">Conteúdo da denúncia</label>
                    <textarea v-model="form.conteudo" type="text" name="conteudo" id="conteudo" rows="3" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
                </div>
                <div class="col-span-12 md:col-span-6">
                    <label for="procedimentos" class="block text-sm font-medium">Procedimentos / encaminhamentos pós recebimento da denúncia</label>
                    <textarea v-model="form.procedimentos" type="text" name="procedimentos" id="procedimentos" rows="3" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
                </div>
                <div class="col-span-12 md:col-span-6">
                    <label for="resposta" class="block text-sm font-medium">Texto de resposta do protocolo (que será consultado pelo denunciante)</label>
                    <textarea v-model="form.resposta" type="text" name="resposta" id="resposta" rows="3" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
                    <button @click="atualizarResposta" type="button" class="inline-flex justify-center py-2 px-6 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-600">
                        Atualizar resposta protocolo
                    </button>
                </div>
            </div>
       
            <div class="grid grid-cols-2 mt-5">
                <div class="py-3 text-left">
                    <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="save()" type="button" class="inline-flex justify-center py-2 px-6 ml-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-600">
                        Salvar até aqui
                    </button>
                    <button @click="save('Concluído')" type="button" class="inline-flex justify-center py-2 px-6 ml-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                        Concluir análise 
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            inicialmenteDesativada: false,
            route: 'denuncias',
            form: null,
        }
    },
    methods: {
        async atualizarResposta () {
            this.form.respostaAtualizada = this.form.resposta;
            const req = await this.$http.put(`/v1/${this.route}/`, this.form);
            if (req.data.success) {
                this.$vToastify.success("Resposta de protocolo atualizada!");
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async save(opcao) {
            if(opcao === 'Concluído') {
                this.form.finalizado = true;
                this.form.status = 'Concluído';
            }

            const req = await this.$http.put(`/v1/${this.route}/`, this.form);
            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async excluirDefinitivamente(){
            this.$confirm({
                title: 'Excluir definitivamente',
                message: `Deseja excluir a denúncia selecionada dos registros?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if(!confirm) return;
                    const id = this.$route.params.id;
                    await this.$http.delete(`/v1/${this.route}/excluir/${id}`);
                    this.$vToastify.success("Removido com sucesso!");
                    this.$router.back();
                }
            })
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            if(!this.form.ativo) this.inicialmenteDesativada = true;
        }
    },
}
</script>